<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1 d-none"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <users-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter un client</h3>
      <user-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <p v-if="users.length == 0" class="text-center text-muted">
      <em>Aucun résultat</em>
    </p>
    <table class="table table-hover">
      <tbody>
        <template v-for="user in users">
          <tr :key="user.id" @click.prevent="$router.push(`/users/${user.id}`)">
            <td>
              <h4>
                {{ user.fullname }}
                <span class="text-muted">
                  {{ user.email }}
                </span>
                <span
                  v-if="user.is_business_customer === true"
                  class="badge badge-dark"
                >
                  PRO
                </span>
                <br />
              </h4>
            </td>
            <td>
              <span
                v-if="user.role"
                :class="{
                  'badge-primary': user.role == 'CUSTOMER',
                  'badge-secondary': user.role != 'CUSTOMER',
                }"
                class="badge mr-1"
              >
                {{ $t(`ROLES.${user.role}`) }}
              </span>
            </td>
            <td>
              {{ $dayjs(user.created_at).format('DD-MM-YYYY') }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ users }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import UsersFilters from './UsersFilters.vue'

export default {
  components: {
    BCard,
    UsersFilters,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      users: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 25,
        term: null,
        role: 'CUSTOMER',
        is_business_customer: false,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      if (this.filters.term) {
        this.displayFilters = true
      }
      const resp = await this.$http.get('/users/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields':
            'results{id,fullname,role,email,created_at,is_business_customer},meta',
        },
      })
      // console.log(JSON.parse(JSON.stringify(resp.data.results)))
      this.users = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(user) {
      this.displayForm = false
      this.$router.push(`/users/${user.id}/`)
    },
  },
}
</script>

<style></style>
