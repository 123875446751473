<template>
  <div>
    <b-form-group label-for="user-term">
      <b-form-input
        id="user-term"
        v-model="options.term"
        placeholder="Recherche par nom, code adresse"
        autofocus
        @change="updateFilters()"
      />
    </b-form-group>
    <b-form-group label="">
      <span class="pl-1 pr-1"> Client Pro </span>
      <el-switch
        v-model="options.is_business_customer"
        @change="updateFilters()"
      >
      </el-switch>
    </b-form-group>
    <b-form-group label-for="input-role">
      <b-form-select
        v-model="options.role"
        placeholder="Role"
        @change="updateFilters()"
      >
        <template #first>
          <b-form-select-option :value="null" disabled>
            -- Filtrer par role --
          </b-form-select-option>
        </template>
        <b-form-select-option
          v-for="option in ['CUSTOMER', 'GUEST']"
          :key="option"
          :value="option"
        >
          {{ $t(`ROLES.${option}`) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <!-- <pre>{{ options }}</pre> -->
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'

export default {
  name: 'UserFilters',
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: {
        term: null,
        role: 'CUSTOMER',
        is_business_customer: false,
      },
    }
  },
  created() {
    this.options = Object.assign(this.options, this.filters)
  },
  methods: {
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.options, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
  },
}
</script>
